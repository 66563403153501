body {
	background-color: #ededed;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

input:-webkit-autofill {
	-webkit-text-fill-color: inherit !important;
	box-shadow: 0 0 0 100px white inset !important;
}

::file-selector-button {
	display: none;
}

.ql-editor {
	min-height: 200px;
}
